// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-artist-contentful-artist-slug-js": () => import("./../../../src/pages/artist/{contentfulArtist.slug}.js" /* webpackChunkName: "component---src-pages-artist-contentful-artist-slug-js" */),
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-commercials-js": () => import("./../../../src/pages/commercials.js" /* webpackChunkName: "component---src-pages-commercials-js" */),
  "component---src-pages-film-tv-contentful-film-television-slug-js": () => import("./../../../src/pages/filmTV/{contentfulFilmTelevision.slug}.js" /* webpackChunkName: "component---src-pages-film-tv-contentful-film-television-slug-js" */),
  "component---src-pages-film-tv-js": () => import("./../../../src/pages/filmTV.js" /* webpackChunkName: "component---src-pages-film-tv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-contentful-info-slug-js": () => import("./../../../src/pages/info/{contentfulInfo.slug}.js" /* webpackChunkName: "component---src-pages-info-contentful-info-slug-js" */),
  "component---src-pages-installations-contentful-installations-and-exhibitions-slug-js": () => import("./../../../src/pages/installations/{contentfulInstallationsAndExhibitions.slug}.js" /* webpackChunkName: "component---src-pages-installations-contentful-installations-and-exhibitions-slug-js" */),
  "component---src-pages-installations-js": () => import("./../../../src/pages/installations.js" /* webpackChunkName: "component---src-pages-installations-js" */),
  "component---src-pages-production-contentful-studio-work-slug-js": () => import("./../../../src/pages/production/{contentfulStudioWork.slug}.js" /* webpackChunkName: "component---src-pages-production-contentful-studio-work-slug-js" */),
  "component---src-pages-production-js": () => import("./../../../src/pages/production.js" /* webpackChunkName: "component---src-pages-production-js" */)
}

