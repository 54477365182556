import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Header = ({ siteTitle, menuLinks }) => (
  <div class="container">
    <nav class="d-flex flex-wrap justify-content-center pt-5 pb-3 my-4">
      <Link to="/">
        <h1>{siteTitle}</h1>
      </Link>
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <ul class="nav">
          {menuLinks.map(link => (
            <li class="nav-item">
              <AniLink
                cover
                direction="up"
                bg="white"
                class="nav-link link-dark"
                activeClassName="nav-link link-dark"
                partiallyActive={true}
                to={link.link}
              >
                {link.name}
              </AniLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
