import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import TransitionLink, {
  TransitionPortal
} from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Header from "./header";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  /*    <Header
        menuLinks={data.site.siteMetadata.menuLinks}
        siteTitle={data.site.siteMetadata.title}
      /> */

  return (
    <>
      {
        <div class="header">
        <div class="container">
          <nav class="d-flex flex-wrap justify-content-center pt-5 pb-3 my-4">
            <AniLink
              cover
              direction="up"
              bg="white"
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
              to="/"
            >
              <h1>{data.site.siteMetadata.title}</h1>
            </AniLink>
            <div class="d-flex align-items-center mb-3 mb-md-0">
              <ul class="nav">
                {data.site.siteMetadata.menuLinks.map(link => (
                  <li class="nav-item">
                    <AniLink
                      cover
                      direction="up"
                      bg="white"
                      className="nav-link link-dark"
                      activeClassName="active"
                      partiallyActive={false}
                      to={link.link}
                    >
                      {link.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          </div>
        </div>
      }
      <div class="container">
        <main>{children}</main>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
